*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  padding-top: 10vh;
}

li {
  list-style: none;
  color: black;
}

a {
  text-decoration: none;
  color: black;
}

.nav__link:hover {
  color: #000000;

}

.nav__item:hover {
  color: coral;
}

.nav__item #active {
  color: coral;
  font-weight: bolder;
}

/* underline style */
.nav__item a::after {
  content: '';
  width: 0;
  height: 3px;
  background-color: coral;
  margin: auto;
  display: block;
}

.nav__item a:hover::after {
  width: 100%;
  transition: width 0.3s linear;
}

.nav__link {
  font-size: 20px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 40px;
  height: 10vh;
  background: rgb(255, 255, 255);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
}

.navbar-brand {
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
}

.trustName {
  font-size: 25px;
  margin-left: 40%;
}

.d-inline-block {
  border-radius: 50px;
  margin: 10px;
  margin-left: 40px;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
}

.nav__toggle div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background-color: black;
}

.nav__toggle {
  cursor: pointer;
  display: none;
  padding-bottom: 5px;
}

.next-button {
  position: absolute;
  right: 1%;
  font-size: 30px;
  color: #6e6e6e;
}

.previous-button {
  position: absolute;
  left: 1%;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.next-button:hover {
  color: white;
}

.previous-button:hover {
  color: white;

}

@media screen and (max-width: 815px) {
  .nav__item a::after {
    content: '';
    width: 0;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: auto;
    display: block;
  }

  .nav__item a:hover::after {
    width: 100%;
    transition: width 0.3s linear;
  }

  .nav__toggle {
    display: block;
  }

  .trustName {
    font-size: 18px;
    margin-left: 30%;
  }

  .nav__link {
    padding-right: 90px;
  }

  .d-inline-block {
    margin-top: 6px;
    margin-left: 5px;
  }

  .nav__item #active {
    background-color: transparent;
    color: rgb(0, 0, 0);
    font-weight: bolder;
  }

  .nav__item {
    margin-top: 10px;
  }

  .nav__item:hover {
    font-weight: bold;
    font-size: 10px;
  }

  .nav__menu {
    position: fixed;
    top: 10vh;
    right: 0;
    width: 45%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 999;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.315);
    backdrop-filter: blur(10px);
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.4s ease-in;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
  }

  /* this is gallery style */

}

@media screen and (max-width: 800px) {
  .next-button {
    position: absolute;
    right: 20%;
    bottom: 20%;
    font-size: 15px;
  }

  .previous-button {
    position: absolute;
    left: 20%;
    bottom: 20%;
    font-size: 15px;
  }
}

.nav__active {
  transform: translateX(0);
  padding-left: 25px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  column-gap: 30px;
}

.img {
  padding-top: 50px;
  padding-bottom: 80px;
}

.img-s {
  margin-right: 20px;
}

/* this is footer style */
#footer-text-hover {
  color: red;
}

.btn-floating {
  font-size: 23px;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.bottom {
  display: contents;
  margin-right: 90px;
  margin-left: 90px;
}

.footer-title {
  font-size: 18px;
}

.footer-team,
.footer-links {
  margin: 0px;
  padding: 0px;
  width: 150px;
}

/* this is gallery style */

.img-display {
  width: 100%;
  display: block;
  cursor: zoom-in;
}

.img-popup-background {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.873);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  scroll-behavior: none;
}

.img-popup {
  width: auto;
  max-width: 85%;
  max-height: 83%;
  margin-top: -70px;
  position: relative;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  color: gray;
  border: none;
  font-size: 40px;
}

.close-button:hover {
  color: white;
}

.previous-button,
.next-button {
  border: none;
  font-size: 30px;
  padding-left: 15px;
  padding-right: 15px;
  color: #6e6e6e;
  background-color: rgba(0, 0, 0, 0)
}

@media screen and (max-width: 800px) {
  .img-popup {
    max-height: 56%;
    margin-top: -110px;
  }
}

/* banner style */

.entry-header {
  box-shadow: 0 5px 15px rgb(25, 25, 25);
  background-image: url("./file/img/photobanner4.png");
  background-size: cover;
  background-position: center;
  min-height: 120px;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-left: 110px;
  display: flex;
  justify-content: start;
  background-color: #6e6e6e;
  background-blend-mode: overlay;
}

@media screen and (max-width: 800px) {
  .entry-header {
    padding-left: 40px;
  }
}

.nv-title-meta-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px;
  max-width: var(--container);
}

.entry-title {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-top: 10px;
}

.breadcrumb {
  font-size: 20px;
}

.breadcrumb-item Link {
  color: white;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* contact page style 

google map style */

.google-map {
  overflow: hidden;
  height: 0;
  padding-bottom: 30%;
  position: relative;
  padding-bottom: 600px;
  margin-left: 80px;
  margin-right: 80px;
  box-shadow: 0 10px 20px rgb(40, 40, 40);

}

@media screen and (max-width: 800px) {
  .google-map {
    padding-bottom: 300px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.google-map iframe {
  border: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

/* contact info style */

.heading .contact-us-top {
  font-size: 30px;
}

.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(./file/img/bgtop.jpeg);
  background-size: cover;
  margin-top: 40px;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}

.contact .content h2 {
  font-size: 45px;
  font-weight: 500;
  color: white;
}

.contact .content p {
  font-weight: 400;
  color: white;
  font-size: 35px;
}

.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.contact-container .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.contact-container .contactInfo .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.contact-container .contactInfo .box .icon {
  min-width: 60px;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
}

.contact-container .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 19px;
  color: white;
  flex-direction: column;
  font-weight: 300;
}

.contact-container .contactInfo .box .text h2 {
  font-weight: 500;
  color: #00bcd4;
}

.contactForm {
  width: 40%;
  padding: 40px;
  background: #fff;
  box-shadow: 0 10px 20px rgb(40, 40, 40);
}

.contactForm h2 {
  font-size: 30px;
  color: #333;
  font-weight: 500;
}

.contactForm .inputBox {
  position: relative;
  width: 100%;
}

.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.3s;
  color: #666;
}

.contactForm .inputBox input:focus~span,
.contactForm .inputBox input:valid~span,
.contactForm .inputBox textarea:focus~span,
.contactForm .inputBox textarea:valid~span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
  letter-spacing: 1.5px;
}

.contactForm .inputBox input[type="submit"] {
  width: 100px;
  background: #00bcd4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}

@media (max-width:991px) {
  .contact {
    padding: 50px;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-container .contactInfo {
    margin-bottom: 40px;
  }

  .contact-container .contactInfo,
  .contactForm {
    width: 100%;
  }
}

/* about page style */

.heading {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 20px auto;
}

.heading h1 {
  font-size: 50px;
  color: black;
  margin-bottom: 25px;
  position: relative;
}

.heading h1::after {
  content: "";
  position: relative;
  width: 100%;
  height: 4px;
  display: block;
  margin: 0 auto;
  background-color: coral;
}

.heading p {
  font-size: 22px;
  color: #666;
  margin-bottom: 35px;
}

.about-container {
  width: 90%;
  margin: 0 auto;
  padding: 10px 20px;
}

.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.about-image {
  flex: 1;
  margin-right: 40px;
  overflow: hidden;
  border-radius: 5px;
}

#about-img {
  margin-right: auto;
  margin-left: 40px;
}

.about-image img {
  max-width: 100%;
  height: auto;
  display: block;
  transition: 0.5s ease;
}

.about-image:not(#about-image-id):hover img {
  transform: scale(1.2);
}

.about-content {
  flex: 1;
}

.about-content h2 {
  font-size: 23px;
  margin-bottom: 15px;
  color: #333;
}

.about-content p {
  font-size: 22px;
  line-height: 1.5;
  color: #666;
}

.line {
  width: 80%;
  height: 2px;
  border: 0 none;
  margin-right: auto;
  margin-left: auto;
  background-image: linear-gradient(to right, transparent, rgba(60, 60, 60, 0.657), transparent);
  margin-bottom: 50px;
}

@media screen and (max-width: 1010px) {
  .heading {
    padding: 0px 20px;
  }

  .heading h1 {
    font-size: 36px;
  }

  .heading p {
    font-size: 17px;
    margin-bottom: 0px;
  }

  .about-container {
    padding: 0px;
  }

  .about {
    padding: 20px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .about-image {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .about-content p {
    padding: 0px;
    font-size: 16px;
  }

  .line {
    margin-bottom: 0px;
  }

  #about-img {
    margin-right: auto;
    margin-left: auto;
  }
}

/* about page card style */
#carousel-style {
  object-fit: fill;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}


.card {
  width: 325px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253);
  margin: 20px;
}

.card img {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 16px;
}

.card-content h3 {
  font-size: 28px;
  margin-bottom: 8px;
}

.card-content p {
  font-size: 15px;
  color: #666;
  line-height: 1.3;
}

/* ourservices page style */

.service-container {
  width: 90%;
  margin: 0 auto;
  padding: 10px 20px;
}

.services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.services-image {
  flex: 1;
  margin-right: 40px;
  overflow: hidden;
  border-radius: 5px;
}

#about-img {
  margin-right: auto;
  margin-left: 40px;
}

.services-image img {
  max-width: 100%;
  height: auto;
  display: block;
  transition: 0.5s ease;
}

.services-image:hover img {
  transform: scale(1.2);
}

.services-content {
  flex: 2;
}

.services-content h2 {
  font-size: 23px;
  margin-bottom: 15px;
  color: #333;
}

.services-content p {
  font-size: 22px;
  line-height: 1.5;
  color: #666;
}

.services-content li {
  font-size: 22px;
  line-height: 1.5;
  color: #666;
}

.line {
  width: 80%;
  height: 2px;
  border: 0 none;
  margin-right: auto;
  margin-left: auto;
  background-image: linear-gradient(to right, transparent, rgba(60, 60, 60, 0.657), transparent);
  margin-bottom: 50px;
}

@media screen and (max-width: 1010px) {
  .heading {
    padding: 0px 20px;
  }

  .heading h1 {
    font-size: 36px;
  }

  .heading p {
    font-size: 17px;
    margin-bottom: 0px;
  }

  .service-container {
    padding: 0px;
  }

  .services {
    padding: 20px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .services-image {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .services-content p {
    padding: 0px;
    font-size: 16px;
  }

  .services-content li {
    padding: 0px;
    font-size: 16px;
  }

  .line {
    margin-bottom: 0px;
  }

  #about-img {
    margin-right: auto;
    margin-left: auto;
  }
}


.c-item {
  height: 480px;
}

.c-img {
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

.carousel-btn {
  width: 90px;
  height: 40px;
}

/* about page style */

.heading .abt-org {
  font-size: 25px;
  color: #666;
  margin-bottom: 35px;
}

.about-content {
  width: 90%;
  margin: 0 auto;
  padding: 10px 20px;
}

.about p {
  font-size: 22px;
  line-height: 1.5;
  color: #666;
  margin-top: 30px;
}

.our-motto-img {
  width: 80%;
}

.our-motto-content p {
  font-size: 22px;
  line-height: 1.5;
  color: #666;
  margin-top: 30px;
}

#about-id {
  flex-direction: column;
}

#about-id .about-image {
  width: 75%;
}

#about-image-id {
  flex: 2;
}

#text-align-id {
  text-align: center;
}

/* TERMS AND CONDITIONS style */

.TC,
.PP,
.DIS,
.RP {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 30px;
}

.TC-header,
.PP-header,
.DIS-header,
.RP-header {
  text-align: center;
}

.TC-content,
.PP-content,
.DIS-content,
.RP-content {
  padding-left: 25px;
}

/* donation page style */

.wrapper {
  margin: 100px;
}

.wrapper {
  margin-bottom: 100px;
  background-color: #fff;
  width: 500px;
  padding: 25px;
  margin: 25px auto 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.wrapper h2 {
  background-color: #fcfcfc;
  color: #21cdd3;
  font-size: 24px;
  padding: 10px;
  margin-bottom: 8px;
  text-align: center;
  border: 1px dashed #21cdd3;
}

.wrapper h4 {
  padding-bottom: 5px;
  color: #21cdd3;
}

.input_group {
  margin-bottom: 8px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
}

.input_box {
  width: 100%;
  margin-right: 12px;
  position: relative;
}

.input_box:last-child {
  margin-right: 0;
}

.input_box .name {
  padding: 14px 10px 14px 50px;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #0003;
  outline: none;
  letter-spacing: 1px;
  transition: 0.3s;
  border-radius: 3px;
  color: #333;
}

.input_box .name:focus {
  -webkit-box-shadow: 0 0 2px 1px #21cdd3;
  -moz-box-shadow: 0 0 2px 1px #21cdd3;
  box-shadow: 0 0 2px 1px #21cdd3;
  border: 1px solid #21cdd3;
}

.input_box .icon {
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  color: #333;
  background-color: #f1f1f1;
  border-radius: 2px 0 0 2px;
  transition: 0.3s;
  font-size: 20px;
  pointer-events: none;
  border: 1px solid #00000003;
  border-right: none;
}

.name:focus+.icon {
  background-color: #21cdd3;
  color: #fff;
  border-right: 1px solid #21cdd3;
  border: none;
  transition: 1s;
}

.input_box button {
  width: 100%;
  background-color: #21cdd3;
  border: none;
  color: #fff;
  padding: 15px;
  border-right: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.input_box button:hover {
  cursor: pointer;
  background-color: #05b1a3;
}

/* donation page style */
.DonationForm {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-transform: capitalize;
  transition: all .2s linear;
}

.DFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  min-height: 70vh;

}

.DFormContainer form {
  padding: 20px;
  width: 700px;
  background: #ffffff;
  box-shadow: 0 20px 25px rgba(0, 0, 0, .5);
}

.DFormContainer form .DFormRow {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.DFormContainer form .DFormRow .DFormCol {
  flex: 1 1 250px;
}

.DFormContainer form .DFormRow .DFormCol .DFormTitle {
  font-size: 20px;
  color: #333;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.DFormContainer form .DFormRow .DFormCol .DFormInputBox {
  margin: 15px 0;
}

.DFormContainer form .DFormRow .DFormCol .DFormInputBox span {
  margin-bottom: 10px;
  display: block;
}

.DFormContainer form .DFormRow .DFormCol .DFormInputBox input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 15px;
  text-transform: none;
}

.DFormContainer form .DFormRow .DFormCol .DFormInputBox input:focus {
  border: 1px solid #000;
}


.DFormContainer form .DFormSubmit-btn {
  width: 100%;
  padding: 12px;
  font-size: 17px;
  background: #27ae60;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
}

.DFormContainer form .DFormSubmit-btn:hover {
  background: #2ecc71;
}

.S_container {
  margin-top: 10%;
}